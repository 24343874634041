import React, { Component } from 'react';

class ExpandDOM extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    this.onExpand = this.onExpand.bind(this)
  }

  async onExpand(event) {
    if('onExpand' in this.props && this.props.data){
        this.props.onExpand(this.props.data);
    }
  }

  render() {
    const styles = {
      position: 'absolute',
      left: (this.props.hasSubtext) ? '-21px' : '-21px',
      top: '-15px',
      height: '20px',
      width: '20px',
      fontSize: '16px'
    };

    return (
      <div style={styles}>
        <button
          className="btn-link"
          onClick={(e) => this.onExpand(e)}
          onMouseEnter={() => document.body.style.cursor = "pointer"}
          onMouseLeave={() => document.body.style.cursor = "default"}>

          <i className="fa fa-arrows-alt" />
        </button>
      </div>

      // <div style={styles}>
      //   <svg height="20px" width="20px">
      //     <polygon points="20 0, 7 4, 16 13" stroke="grey" fill="grey" />
      //     <line x1={11.5} y1={8.5} x2={0} y2={20} stroke="grey" fill="grey" strokeWidth={2} />
      //   </svg>
      // </div>
    );
  }
}

export default ExpandDOM;
